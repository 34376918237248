import * as React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

export default function DashboardError() {
  return (
    <Card>
      <CardHeader title="Error" />
      <CardContent>You have no access</CardContent>
    </Card>
  );
}
