import * as React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

export default function DashboardSucces() {
  return (
    <Card>
      <CardHeader title="Welcome" />
      <CardContent>Welcome, browse data on the left tabs</CardContent>
    </Card>
  );
}
