import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
  ImageField,
} from "react-admin";
import { useRecordContext } from "react-admin";

export const ShowArtwork = () => (
  <Show title={<ArtworkTitle />}>
    <SimpleShowLayout>
      <ReferenceField source="artist" reference="profiles">
        <TextField source="fullName" />
      </ReferenceField>
      <ImageField source="images[0]" label="Image" />
      <DateField source="createDate" label="Upload date" />
    </SimpleShowLayout>
  </Show>
);

const ArtworkTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `"${record.title}"` : ""}</span>;
};
