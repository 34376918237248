import * as React from "react";
import { Filter, TextInput } from "react-admin";
import { QuickFilter } from "./ArtworkList";

export default function ArtworkFilter(props) {
  return (
    <Filter {...props}>
      <TextInput source="title" label="Art title" resettable alwaysOn />
      <TextInput source="owner" label="Owner" />
      <QuickFilter source="price.type" label="fixed" defaultValue="fixed" />
    </Filter>
  );
}
