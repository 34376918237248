import * as React from "react";
import { Filter, TextInput } from "react-admin";

export default function UserFilter(props) {
  return (
    <Filter {...props}>
      <TextInput source="userName" label="User Name" resettable alwaysOn />
      <TextInput source="fullName" label="Full name" />
    </Filter>
  );
}
