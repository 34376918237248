import * as React from "react";
import PostIcon from "@mui/icons-material/Book";
import UserIcon from "@mui/icons-material/Group";
import DashboardError from "./components/dashboard/DashboardError";
import DashboardSucces from "./components/dashboard/DashboardSuccess";
import { Admin, Resource } from "react-admin";
import { UserList } from "./components/users/UserList";
import { ArtworkList } from "./components/artwork/ArtworkList";
import { ShowArtwork } from "./components/artwork/ShowArtwork";
import { EditUser } from "./components/users/EditUser";
import firebaseConfig from "./firebaseConfig";
import {
  FirebaseAuthProvider,
  FirebaseDataProvider,
} from "react-admin-firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebase from "firebase/compat/app";
import { useState } from "react";

const App = () => {
  const dataProvider = FirebaseDataProvider(firebaseConfig);
  const authProvider = FirebaseAuthProvider(firebaseConfig);
  const [isStaff, setIsStaff] = useState(true);
  const auth = getAuth();

  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // User is signed in
      const db = firebase.firestore();
      const response = db.collection("profiles").where("user", "==", user.uid);
      const data = await response.get();
      const loggedInUserData = data.docs[0].data();
      if (loggedInUserData.isStaff) {
        setIsStaff(true);
      }
    } else {
      // User is signed out
      setIsStaff(false);
    }
  });
  return (
    <div>
      {isStaff ? (
        <Admin
          dashboard={DashboardSucces}
          dataProvider={dataProvider}
          authProvider={authProvider}
        >
          <Resource
            name="profiles"
            list={UserList}
            edit={EditUser}
            icon={UserIcon}
          />
          <Resource
            name="artworks"
            list={ArtworkList}
            show={ShowArtwork}
            icon={PostIcon}
          />
        </Admin>
      ) : (
        <Admin dashboard={DashboardError} authProvider={authProvider}>
          <Resource />
        </Admin>
      )}
    </div>
  );
};

export default App;
