const firebaseConfig = {
  apiKey: "AIzaSyDCxJ3ehi1SEMeyo4KypMN4WfDn2TTRB6k",
  authDomain: "qoonst-781dd.firebaseapp.com",
  projectId: "qoonst-781dd",
  storageBucket: "qoonst-781dd.appspot.com",
  messagingSenderId: "883962982559",
  appId: "1:883962982559:web:009cb9d44afec45915b2aa",
  measurementId: "G-7FGE41SLJ9",
};

export default firebaseConfig;
