import * as React from "react";
import { List, Datagrid, TextField, EditButton, EmailField } from "react-admin";
import UserFilter from "./UserFilter";

export const UserList = () => (
  <List filters={<UserFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="userName" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <EmailField source="email" />
      <EditButton />
    </Datagrid>
  </List>
);
