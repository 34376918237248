import * as React from "react";
import { Chip } from "@mui/material";
import { useTranslate } from "react-admin";
import { List, Datagrid, TextField, ReferenceField } from "react-admin";
import ArtworkFilter from "./ArtworkFilter";

export const ArtworkList = () => {
  return (
    <List filters={<ArtworkFilter />}>
      <Datagrid rowClick="show">
        <TextField multiline="true" source="title" />
        <ReferenceField source="artist" reference="profiles">
          <TextField source="fullName" />
        </ReferenceField>
        <TextField source="price.value" />
        <TextField source="price.currency" />
        <TextField source="price.type" />
      </Datagrid>
    </List>
  );
};

export const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};
