import { Edit, SimpleForm, TextInput } from "react-admin";
import { useRecordContext } from "react-admin";

export const EditUser = () => (
  <Edit title={<UserTitle />}>
    <SimpleForm>
      <TextInput source="userName" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
    </SimpleForm>
  </Edit>
);

const UserTitle = () => {
  const record = useRecordContext();
  return <span>{record ? `"${record.userName}"` : ""}</span>;
};
